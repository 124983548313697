<template>
  <div>
    <div v-if="variable" class="vp-text-gray-500 vp-text-xs">Starting From</div>

    <div
      class="vp-flex"
      :class="{ 'vp-items-center vp-space-x-2': !stack, 'vp-flex-col': stack }"
    >
      <Amount
        class="vp-font-bold vp-flex vp-leading-none vp-text-lg"
        :value="price"
      />
      <Amount
        v-if="regular && !variable"
        class="vp-flex vp-line-through vp-text-gray-500"
        :value="regular"
        :class="{ 'vp-ml-1': !stack }"
      />
    </div>
  </div>
</template>

<script>
import Amount from "./amount.vue";

export default {
  components: {
    Amount,
  },

  props: {
    price: Number,
    regular: Number,
    variable: Boolean,
    stack: Boolean,
  },
};
</script>
