<template>
  <div
    class="vp-relative vp-overflow-hidden vp-rounded-md"
    :class="sizes[size]"
  >
    <img
      v-if="image"
      :src="image"
      class="vp-border-0 vp-w-full vp-h-full vp-object-cover"
    />
    <div
      v-else-if="name"
      class="vp-w-full vp-text-sm vp-flex vp-items-center vp-justify-center vp-h-full vp-text-white/50"
      :class="[randomColor()]"
    >
      {{ name?.charAt(0) }}
    </div>
    <div
      v-else
      class="vp-w-full vp-h-full vp-bg-gray-200 vp-flex vp-items-center vp-justify-center"
      @click="$emit('select')"
    >
      <Icon
        class="vp-text-gray-300 vp-w-8 vp-h-8"
        :name="$options.icons.Contact"
      />
    </div>
  </div>
</template>
<script>
import { Contact } from "icons/icons.js";
import Icon from "./icon.vue";

export default {
  icons: {
    Contact,
  },
  props: {
    colorIndex: {
      type: [Number, String],
      default: null,
    },
    name: {
      type: String,
    },
    image: String,
    size: String,
  },

  components: {
    Icon,
  },
  data() {
    return {
      colors: [
        "vp-bg-danger-500",
        "vp-bg-warning-500",
        "vp-bg-purple-500",
        "vp-bg-indigo-500",
        "vp-bg-blue-500",
        "vp-bg-cyan-500",
        "vp-bg-lime-500",
        "vp-bg-success-500",
        "vp-bg-pink-500",
        "vp-bg-gray-500",
        "vp-bg-amber-500",
      ],
      // TODO: Refactor this sizes.
      sizes: {
        xxs: "vp-h-8 vp-w-8",
        xs: "vp-h-10 vp-w-10",
        sm: "vp-h-14 vp-w-14",
        md: "vp-h-24 vp-w-24",
        lg: "vp-h-40 vp-w-40",
        xl: "vp-h-40 vp-w-80",
        full: "vp-h-full vp-w-full",
      },
    };
  },
  methods: {
    randomColor() {
      const nameIndex = this.name.charCodeAt(0) % 10;
      return this.colors[this.colorIndex || nameIndex];
    },
  },
};
</script>
