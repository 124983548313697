<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions>
      <DocsLink #default="{ storeProduct }">
        <VpPortalButton
          label="User Guide"
          :href="storeProduct"
          target="_blank"
        />
      </DocsLink>
      <VpPortalButton label="Bulk Actions" @click.native="checkBulkAction()" />
      <VpAddItem :feature="feature" @click="openAddProductModal" />
    </VpHeaderActions>

    <!-- List -->
    <!-- Endpoint need to make dynamic due to drag column is based on categoryId selection and stored in localStorage -->
    <VpList
      :per-page="10"
      :columns="columns"
      :endpoint="
        categoryId
          ? `catalog-products-categorywise-list-${categoryId}`
          : 'catalog-products-list'
      "
      :filters.sync="filters"
      :request-payload="{
        query: $options.products,
        staticFilters: {
          categoryId: categoryId ? Number(categoryId) : null,
        },
      }"
      class="vp-h-full"
      ref="list"
      title="Products"
      bulk
      @rowClick="$router.push(link($event))"
      :reorder="Boolean(categoryId)"
      @reorder="
        reorder('products', $event, {
          categoryId: Number(categoryId),
        })
      "
      @refresh="feature.refresh"
      feature="PRODUCT"
      :version="4"
    >
      <template #empty>
        <VpListEmpty :feature="feature">
          <template #action>
            <VpAddItem
              @click="$vayu.modal.open('product-type')"
              :feature="feature"
            />
          </template>
        </VpListEmpty>
      </template>

      <!-- FILTERS -->
      <template #filters>
        <VpField label="Status">
          <VpRadioGroup
            name="status"
            v-model="filters.status"
            :options="statusOption"
          />
        </VpField>
        <VpField label="Stock">
          <VpRadioGroup
            name="stack"
            stack
            v-model="filters.isOutOfStock"
            :options="isOutOfStockOption"
          />
        </VpField>
        <VpField label="Store Visibility">
          <VpRadioGroup
            name="Visibility"
            stack
            :options="displayOnStoreOptions"
            v-model="filters.shouldDisplayOnStore"
          />
        </VpField>
        <VpField label="Type">
          <VpRadioGroup
            name="type"
            stack
            :options="productTypeOptions"
            v-model="filters.type"
          />
        </VpField>

        <VpField label="Charges">
          <VpInput>
            <VpSelect
              :options="productStoreCharges"
              v-model.number="filters.chargeId"
              :unselect="false"
            />
          </VpInput>
        </VpField>

        <VpField label="Badges">
          <VpInput>
            <VpSelect
              :options="productBadges"
              v-model.number="filters.tagId"
              :unselect="false"
            />
          </VpInput>
        </VpField>

        <VpListFilterArchived v-model="filters.archived" />
      </template>

      <!-- FIELDS -->
      <template #name="{ item }">
        <LockedItem :item="item" />
        <p>{{ item.name }}</p>
        <p v-if="item.isOutOfStock" class="vp-text-xs vp-text-danger-500">
          Marked as Out of Stock
        </p>
        <template v-if="item.type == 'variable'">
          <p v-if="item.variantCount > 0" class="vp-text-gray-500">
            {{ item.variantCount }} Variants
          </p>
          <p v-else class="text-danger">0 Variants</p>
        </template>
        <p class="vp-text-gray-500" v-else>
          {{ item.measurementWithUnit }}
        </p>
      </template>

      <template #media="{ item }">
        <VpProductMedia :items="item.media" size="100x100" :type="item.type" />
      </template>

      <template #price="{ item }">
        <ProductPrice stack :price="item.price" :regular="item.regularPrice" />
      </template>

      <template #stock="{ item }">
        <VpProductStock :value="item.stock" :is-out-of-stock="false" />
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>

      <!-- BULK -->
      <template #_bulk="{ count, ids }">
        <VpBulkAction
          label="Delete Products"
          :icon="$options.icons.Delete"
          @click="
            checkPermission('PRODUCT', 'delete', () => {
              deleteProducts(ids, count);
            })
          "
        />
        <ProductStatus
          :ids="ids"
          :count="count"
          @refresh="$refs.list.refresh()"
        />
      </template>
    </VpList>
    <Type />
  </div>
</template>

<script>
import ProductPrice from "components/src/product-price.vue";
import { Delete, Download, ProductVariants, Add } from "icons/icons.js";
import { mapGetters } from "vuex";
import LockedItem from "components/src/vp-list/locked-item.vue";
import DocsLink from "components/src/docs-link/main.vue";
import products from "graph/product/collection.gql";

import tags from "graph/tag/collection.gql";
import del from "graph/product/batch/delete.gql";
import storeCharges from "../product-charges/collection.gql";
import reorder from "mixins/reorder.js";

import ProductStatus from "./_ProductStatus.vue";
import Type from "./_Type.vue";

export default {
  products,
  icons: {
    ProductVariants,
    Download,
    Delete,
    Add,
  },
  props: {
    categoryId: [Number, String],
    feature: Object,
  },

  mixins: [reorder],
  components: {
    Type,
    ProductStatus,
    ProductPrice,
    LockedItem,
    DocsLink,
  },
  data() {
    return {
      filters: {
        archived: false,
        status: null,
        isOutOfStock: null,
        shouldDisplayOnStore: null,
        type: null,
        chargeId: 0,
        tagId: 0,
      },
      productStoreCharges: [],
      productBadges: [],
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
      isUserPermitted: "user/isUserPermitted",
      isSubscriptionLimitExceeded: "user/isSubscriptionLimitExceeded",
    }),

    statusOption() {
      return [
        { label: "All", value: null },
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ];
    },

    displayOnStoreOptions() {
      return [
        { label: "All", value: null },
        { label: "Accessible", value: true },
        { label: "Not Accessible", value: false },
      ];
    },

    isOutOfStockOption() {
      return [
        { label: "All", value: null },
        { label: "Available", value: "0" },
        { label: "Out of Stock", value: "1" },
      ];
    },

    productTypeOptions() {
      return [
        { label: "All", value: null },
        { label: "Simple", value: "simple" },
        { label: "Variable", value: "variable" },
        { label: "Digital", value: "digital" },
      ];
    },

    columns() {
      return [
        { name: "media", fix: true, width: "70px", type: "string" },
        {
          name: "name",
          sortable: true,
          type: "string",
        },
        { name: "price", fix: true, sortable: true, type: "number" },
        {
          name: "sku",
          label: "SKU",
          fix: true,
          sortable: true,
          type: "string",
        },
        {
          name: "stock",
          label: "Stock",
          fix: true,
          sortable: true,
          type: "string",
        },
        { name: "status", fix: true, sortable: true, type: "string" },
      ];
    },
  },

  mounted() {
    this.getPrductCharge();
    this.getPrductBadges();
  },

  methods: {
    checkBulkAction() {
      if (this.isUserPermitted("PRODUCT_BULK_UPLOAD", "upsert")) {
        this.$router.push({ name: "products-bulk" });
      } else {
        this.$vayu.modal.open("unauthorized-access-modal");
      }
    },

    openAddProductModal() {
      this.$vayu.modal.open("product-type");
    },

    link(product) {
      if (this.categoryId) {
        return {
          name: "product",
          params: {
            productId: product.id,
          },
          query: {
            categoryId: this.categoryId,
          },
        };
      } else {
        return {
          name: "product",
          params: {
            productId: product.id,
          },
        };
      }
    },

    deleteProducts(ids, count) {
      const isConfirm = confirm(
        `Are you sure you want to delete ${count} products?`
      );
      if (isConfirm) {
        this.$mutate(del, {
          id: ids,
        }).then(() => {
          this.feature.refresh(true);
          this.$refs.list.refresh();
        });
      }
    },

    getPrductCharge() {
      return this.$query(storeCharges, {
        page: 1,
        perpage: 10,
      }).then(({ data }) => {
        const response = data.storeCharges.data;
        this.productStoreCharges = response.map((res) => {
          return { label: res.name, value: res.id };
        });
        this.productStoreCharges.splice(0, 0, {
          label: "All",
          value: 0,
        });
      });
    },

    getPrductBadges() {
      return this.$query(tags, {
        page: 1,
        perpage: 10,
        type: 4,
      }).then(({ data }) => {
        const response = data.tags.data;
        this.productBadges = response.map((res) => {
          return { label: res.name, value: parseInt(res.id), color: res.color };
        });
        this.productBadges.splice(0, 0, { label: "All", value: 0 });
      });
    },
  },
};
</script>
