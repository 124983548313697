const state = {
  id: null,
  name: null,
  hash: null,
  status: null,
  currency: null,
  productPriceDisplay: null,
  weightUnit: null,
  profilePicture: null,
};

const getters = {
  name(state) {
    return state.name;
  },
  profilePicture(state) {
    return state.profilePicture;
  },
  isActive(state) {
    return state.hash && state.status == "active";
  },

  id(state) {
    return state.id;
  },

  hash(state) {
    return state.hash;
  },

  weightUnit() {
    return state.weightUnit;
  },
  currencySymbol(state) {
    if (!state.currency) return "?";
    return state.currency.symbol || state.currency.abbreviation;
  },

  currencyDecimals(state) {
    if (!state.currency) return 2;
    return state.currency.decimalPlaces || 2;
  },

  getProductPriceDisplay(state) {
    return state.productPriceDisplay;
  },
};

const mutations = {
  set(state, data) {
    const {
      id,
      hash,
      currency,
      status,
      productPriceDisplay,
      name,
      weightUnit,
      profilePicture,
    } = data;
    state.name = name;
    state.currency = currency;
    state.id = id;
    state.hash = hash;
    state.status = status;
    state.productPriceDisplay = productPriceDisplay;
    state.weightUnit = weightUnit;
    state.profilePicture = profilePicture?.url;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
